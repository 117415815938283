// home/servers/feedcraft/client/src/components/ProductCard.js

import React from 'react';
import { Card, Divider, Dropdown, Menu, Space } from 'antd';
import { EllipsisOutlined, DownOutlined } from '@ant-design/icons';

const { Meta } = Card;

const ProductCard = ({ product, availableWeights }) => {
  const { name, price, price_old, images, slug, characteristics } = product;

  // Отримуємо всі значення призначень або протеїнів товару
  const isProteinCategory = product?.vidKormu?.id === 3;
  const assignmentList = isProteinCategory
    ? characteristics?.tipKormu?.map((item) => item.value.ua) || ['Не вказано']
    : characteristics?.priznachennja?.map((item) => item.value.ua) || ['Не вказано'];

  // Визначаємо, чи є комплекти
  const hasBundles = availableWeights.some((weight) => weight.value.includes('×'));

  // Розділяємо комплекти і звичайну вагу
  const bundles = availableWeights.filter((weight) => weight.value.includes('×'));
  const regularWeights = availableWeights.filter((weight) => !weight.value.includes('×'));

  // Меню для випадаючого списку комплектів
  const bundleMenu = (
    <Menu>
      {bundles.map((bundle, index) => (
        <Menu.Item key={index}>{bundle.value}</Menu.Item>
      ))}
    </Menu>
  );

  // Перетворюємо assignmentList у пункти випадаючого меню без посилань
  const items = assignmentList.map((assignment, index) => ({
    key: index.toString(),
    label: (
      <span key={index} style={{ fontSize: '12px', marginBottom: '5px' }}>
        {assignment}
      </span>
    ),
  }));

  return (
    <Card
      hoverable
      style={{
        width: '100%', // Динамічна ширина
        maxWidth: '300px', // Максимальна ширина картки
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px',
        margin: '10px',
        textAlign: 'left', // Вирівнюємо текст по лівому краю
      }}
      bodyStyle={{
        padding: '6px 20px', // Встановлюємо відступи у картці
      }}
      cover={
        <img
          alt={name}
          src={images?.[0] || 'default-image.jpg'} // Зображення за замовчуванням, якщо немає
          style={{ height: '200px', objectFit: 'contain', padding: '10px' }}
        />
      }
      onClick={() => window.open(`https://homefood.ua/${slug}`, '_blank')}
      actions={[
        // Виводимо звичайні ваги
        ...regularWeights.map((weight, index) => (
          <span key={index} style={{ fontSize: '14px' }}>
            {weight.value}
          </span>
        )),
        // Якщо є комплекти, додаємо іконку "ellipsis" для меню
        ...(hasBundles
          ? [
              <Dropdown key="ellipsis" overlay={bundleMenu}>
                <EllipsisOutlined />
              </Dropdown>,
            ]
          : []),
      ]}
    >
      <Meta
        title={
          <>
            {/* Відображаємо ціну товару перед title */}
            <p style={{ fontSize: '16px', fontWeight: 'bold', margin: '0', color: '#002d5a' }}>
              {price && (
                <>
                  <span>{price} грн</span>
                  {price_old && price_old !== price ? (
                    <>
                      &nbsp;
                      <span
                        style={{
                          textDecoration: 'line-through',
                          color: '#999',
                          fontSize: '12px', // Розмір шрифту для старої ціни
                        }}
                      >
                        {price_old} грн
                      </span>
                    </>
                  ) : null}
                </>
              )}
            </p>
            <span
              style={{
                fontSize: '12px', // Назва тепер має 12px
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3, // Обмеження title до 3 рядків
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                lineHeight: '1.4em',
                height: '4.5em',
                fontWeight: 'normal', // Regular шрифт для name
              }}
            >
              {name}
            </span>
          </>
        }
        description={
          <div style={{ marginTop: '10px' }}>
            {/* Виводимо випадаючий список для призначень або протеїнів товару */}
            <Dropdown
              menu={{
                items,
              }}
              trigger={['hover', 'click']} // Тригером є наведення або клік для мобільних пристроїв
            >
              <div
                style={{
                  cursor: 'pointer',
                  color: 'rgba(0, 0, 0, 0.40)',
                }}
              >
                <Divider
                  orientation="left"
                  style={{ fontSize: '12px', marginTop: '10px', marginLeft: '0px', color: '#333' }} // Темніший колір Divider
                >
                  <Space
                    style={{ transition: 'color 0.3s' }}
                    onMouseEnter={(e) => (e.currentTarget.style.color = '#1890ff')} // Зміна кольору при наведенні
                    onMouseLeave={(e) => (e.currentTarget.style.color = 'rgba(0, 0, 0, 0.40)')} // Повернення кольору
                  >
                    {isProteinCategory ? 'Протеїни' : 'Призначення'} <DownOutlined />
                  </Space>
                </Divider>
              </div>
            </Dropdown>
          </div>
        }
      />
    </Card>
  );
};

export default ProductCard;
