//client/src/controllers/recommendation/dogRecommendationLogic.js

export function calculateDogRecommendations(answers) {
    const characteristics = {};
  
    console.log('Розрахунок характеристик для собак на основі відповідей:', JSON.stringify(answers, null, 2));
    
    // Вік
  if (answers.age?.value === '0-12') {
    characteristics['dljaKogo'] = 2; // id для "Песикам"
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 7]; // id для "Для малюків, для вагітних та годуючих самок"
    characteristics['vk'] = 3; // id для "Цуценята"
  } else if (answers.age?.value === '1-7') {
    characteristics['dljaKogo'] = 2; // id для "Песикам"
    characteristics['vk'] = 1; // id для "Дорослі"
  } else if (answers.age?.value === '7+') {
    characteristics['dljaKogo'] = 2; // id для "Песикам"
    characteristics['vk'] = 1; // id для "Дорослі"
  }

  // Порода
  if (answers.breed?.value === 'MINI') {
      characteristics['dogBreedSize'] = 1; // id для "MINI"
  } else if (answers.breed?.value === 'MEDIUM') {
      characteristics['dogBreedSize'] = 2; // id для "MEDIUM"
  } else if (answers.breed?.value === 'MAXI') {
      characteristics['dogBreedSize'] = 3; // id для "MAXI"
  } else if (answers.breed?.value === 'GIANT') {
      characteristics['dogBreedSize'] = 3; // id для "MAXI"
  }

  // Колір шерсті
  if (answers.furColor?.value === 'white') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 34]; // id для "Для тварин з білою шерстю"
  }

  // Тип шерсті
  if (answers.furTexture?.value === 'curly') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 17]; // id для "Здоров’я шкіри та блиск шерсті"
  } else if (answers.furTexture?.value === 'needsClipping') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 17]; // id для "Здоров’я шкіри та блиск шерсті"
  } else if (answers.furTexture?.value === 'straight') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 17]; // id для "Здоров’я шкіри та блиск шерсті"
  }

  // Тип статури
  if (answers.bodyType?.value === 'emaciated' || answers.bodyType?.value === 'thin') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 24]; // id для "Укріплення захисних функцій організму"
  } else if (answers.bodyType?.value === 'overweight' || answers.bodyType?.value === 'obesity') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 33]; // id для "Для підтримки опорно-рухового апарату"
  }

  // Активність
  if (answers.activity?.value === 'veryActive' || answers.activity?.value === 'mediumActive') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 3]; // id для "Для активних"
  } else if (answers.activity?.value === 'veryActive' || answers.activity?.value === 'mediumActive') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 9]; // id для "Універсальний"
  }

  // Стерилізований/кастрований
  if (answers.neutered?.value === 'yes') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 8]; // id для "Для кастрованих/стерилізованих"
  }

  // Народжує собака
  if (answers.birthFrequency?.value === 'never') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 8]; // id для "Для кастрованих/стерилізованих"
  } else if (['3_times', '2_times', '1_time', 'rarely'].includes(answers.birthFrequency?.value)) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 7, 23]; // id для "Для малюків, для вагітних та годуючих самок" та "Підготовка до пологів"
  }

  // Хронічні захворювання
  if (answers.chronicIllness?.value === 'yes') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 15]; // id для "Ефективний імуномодулятор"
  }

  // Прийом ліків
  if (answers.medications?.value?.includes('insulin') ||
      answers.medications?.value?.includes('nsaids') ||
      answers.medications?.value?.includes('thyroxin') ||
      answers.medications?.value?.includes('steroids') ||
      answers.medications?.value?.includes('contraceptives')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 15]; // id для "Ефективний імуномодулятор"
  }

  // Проблеми з травленням
  if (answers.digestiveIssues?.value?.includes('constipation') ||
      answers.digestiveIssues?.value?.includes('diarrhea') ||
      answers.digestiveIssues?.value?.includes('vomiting')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 1, 11, 14]; // id для "При схильності до алергій" + "При чутливому травленні" + "Профілактика порушень ШКТ"
  }

  // Алергії/непереносимість
  if (answers.allergies?.value === 'yes') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 1]; // id для "При схильності до алергій"
  }

  // Апетит
  if (answers.appetite?.value?.includes('pickySometimes')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 10]; // id для "Для вибагливих"
  }
  if (answers.appetite?.value?.includes('veryPicky')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 10]; // id для "Для вибагливих"
  }

  // Смакові вподобання
  if (answers.tastePreferences?.value?.includes('grainFree')) {
    characteristics['tipKormu'] = [...(characteristics['tipKormu'] || []), 1]; // id для "Беззерновий"
  }
  if (answers.tastePreferences?.value?.includes('withoutChicken')) {
    characteristics['tipKormu'] = [...(characteristics['tipKormu'] || []), 5]; // id для "Без курочки"
  }
  if (answers.tastePreferences?.value?.includes('meat')) {
    characteristics['tipKormu'] = [...(characteristics['tipKormu'] || []), 3]; // id для "На основі м'яса"
  }
  if (answers.tastePreferences?.value?.includes('fish')) {
    characteristics['tipKormu'] = [...(characteristics['tipKormu'] || []), 4]; // id для "На основі риби"
  }

  // Вода
  if (['50-100', '100-300', '300-1000', '1000', 'notTracking'].includes(answers.waterIntake?.value)) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 24, 15]; // id для "Ефективний імуномодулятор" + "Укріплення захисних функцій організму"
  }

  // Тип води
  if (['tapWater', 'filteredWater', 'bottledWater', 'specialDogWater', 'wellWater'].includes(answers.waterType?.value)) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 24, 15]; // id для "Ефективний імуномодулятор" + "Укріплення захисних функцій організму"
  }

  // Тип харчування
  if (answers.dietType?.value?.some(type => ['dryFood', 'wetFood', 'homemade', 'tableScraps'].includes(type))) {
    characteristics['vidKormu'] = [...(characteristics['vidKormu'] || []), 1, 2, 3, 4]; // id для "Основне харчування", "Кормова добавка", "Ласощі", "Фітоміни"
  }

  // Прболеми з поведінкою
  if (['aggression', 'anxiety', 'hyperactivity', 'excessiveBarking', 'destructiveBehavior', 'escaping', 'disobedience', 'otherBehaviorIssues'].includes(answers.behavioural?.value)) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 27]; // id для "Заспокійливе для собак"
  }

  // Особлива увага
  if (answers.specialFocus?.value?.includes('growth')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 24, 7]; // id для "Укріплення захисних функцій організму" + "Для малюків, для вагітних та годуючих самок"
  }
  if (answers.specialFocus?.value?.includes('furImprovement')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 6, 17]; // id для "Для блиску шерсті" + "Здоров’я шкіри та блиск шерсті"
  }
  if (answers.specialFocus?.value?.includes('weightControl')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 19, 14, 2]; // id для "Антипаразитний засіб" + "Профілактика порушень ШКТ" + "Беззерновий"
  }
  if (answers.specialFocus?.value?.includes('jointSupport')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 13, 20, 17]; // id для "Баланс Омега-3, -6, -9" + "Відновлення в післяопераційний період" + "Здоров’я шкіри та блиск шерсті"
  }
  if (answers.specialFocus?.value?.includes('heartSupport')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 21]; // id для "Зміцнення серцево-судинної системи"
  }
  if (answers.specialFocus?.value?.includes('aging')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 15, 24, 21, 18, 13, 14]; // id для "Ефективний імуномодулятор" + "Укріплення захисних функцій організму" + "Зміцнення серцево-судинної системи" + "Відновлення після линяння" + Баланс Омега-3, -6, -9" + "Профілактика порушень ШКТ"
  }
  if (answers.specialFocus?.value?.includes('pregnancyLactation')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 23, 7]; // id для "Підготовка до пологів" + "Для малюків, для вагітних та годуючих самок"
  }

  return characteristics;
}
