//client/src/pages/StatsPage.js

import React, { useState, useEffect } from 'react';
import { Layout, Menu, Row, Col, Card, Statistic, Space, Table, Spin, Select, List } from 'antd';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCat, faDog } from '@fortawesome/free-solid-svg-icons';

import catQuestions from '../components/questions/CatQuestions';
import dogQuestions from '../components/questions/DogQuestions';

const { Content, Footer, Sider } = Layout;
const { Option } = Select;

function getItem(label, key, icon) {
  return { key, icon, label };
}

const items = [
  getItem('Для котів', 'cat', <FontAwesomeIcon icon={faCat} />),
  getItem('Для собак', 'dog', <FontAwesomeIcon icon={faDog} />),
];

const vidKormuOptions = [
  { id: 1, label: 'Основне харчування' },
  { id: 2, label: 'Кормова добавка' },
  { id: 3, label: 'Ласощі' },
  { id: 4, label: 'Фітоміни' },
  { id: 5, label: 'Мюслі' },
];

const StatsPage = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [currentView, setCurrentView] = useState('cat');
  const [statsData, setStatsData] = useState({ totalResponses: 0, questionsData: {}, recommendedProducts: [] });
  const [loading, setLoading] = useState(true);
  const [vidKormuFilter, setVidKormuFilter] = useState([]);

  const questions = currentView === 'cat' ? catQuestions : dogQuestions;

  useEffect(() => {
    const fetchStats = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/stats/recommended-products?petType=${currentView}`);
        console.log("Data fetched from API:", response.data);
  
        const transformedData = transformQuestionsData(response.data.questionsData);
        console.log("Transformed Data:", transformedData);
        setStatsData({ ...response.data, questionsData: transformedData });
  
      } catch (error) {
        console.error("Помилка при отриманні статистики:", error);
        setStatsData({ totalResponses: 0, questionsData: {}, recommendedProducts: [] });
      } finally {
        setLoading(false);
      }
    };
    fetchStats();
  }, [currentView]);  

  const transformQuestionsData = (questionsData) => {
    const transformed = {};
    if (questionsData) {
      Object.entries(questionsData).forEach(([key, value]) => {
        transformed[key] = {
          questionValue: value.questionValue,
          questionText: value.questionText || 'Питання відсутнє',
          answers: value.answers ? formatAnswers(value.answers) : [],
        };
      });
    }
    return transformed;
  };  
  
  const formatAnswers = (answers) => {
    return answers.map(({ label, count }) => ({
      answerLabel: typeof label === 'string' ? label : JSON.stringify(label) || 'Немає відповіді',
      count,
    }));
  };    

  const handleMenuClick = (e) => {
    setCurrentView(e.key);
    setVidKormuFilter([]);
  };

  const handleVidKormuFilterChange = (values) => {
    setVidKormuFilter(values);
  };

  const filteredProducts = vidKormuFilter.length > 0
    ? statsData.recommendedProducts.filter(product => vidKormuFilter.includes(product.vidKormu))
    : statsData.recommendedProducts;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed} width={160}>
        <Menu theme="dark" defaultSelectedKeys={['cat']} mode="inline" items={items} onClick={handleMenuClick} style={{ fontSize: '16px' }} />
      </Sider>
      <Layout>
        <Content style={{ margin: '0 16px' }}>
          <h2 style={{ marginTop: '16px' }}>Статистика для {currentView === 'cat' ? 'котів' : 'собак'}</h2>
          {loading ? (
            <Spin tip="Завантаження..." size="large" />
          ) : (
            <Space direction="vertical" size={24} style={{ width: '100%' }}>
              <Row gutter={16}>
                <Col span={8}>
                  <Card size="small">
                    <Statistic title="Кількість відповідей" value={statsData.totalResponses} valueStyle={{ fontWeight: 'bold', fontSize: '16px' }} />
                  </Card>
                </Col>
              </Row>

              <h3>Статистика відповідей:</h3>
              <Row gutter={[16, 16]}>
  {questions.map(question => {
    const questionData = statsData.questionsData[question.questionValue] || {}; // Додали перевірку на undefined
    const hasAnswers = questionData.answers && questionData.answers.length > 0;
    const answersArray = hasAnswers
      ? questionData.answers
      : [{ answerLabel: 'Немає відповідей', count: 0 }];

    return (
      <Col span={8} key={question.questionValue}>
        <Card size="small" title={questionData.questionText || 'Питання відсутнє'}>
          <List
            dataSource={answersArray}
            renderItem={answer => (
              <List.Item>
                {`${answer.answerLabel}: ${answer.count}`}
              </List.Item>
            )}
          />
        </Card>
      </Col>
    );
  })}
</Row>

              <h3>Рекомендовані продукти:</h3>
              <Table
                dataSource={filteredProducts.map(product => ({ ...product, key: product?.productId || `unknown-${Math.random()}` }))}
                columns={[
                  { 
                    title: <div style={{ textAlign: 'center' }}>Зображення</div>,
                    dataIndex: 'image',
                    key: 'image',
                    render: (image) => (
                      <div style={{ textAlign: 'center' }}>
                        {image ? <img src={image} alt="product" style={{ width: 50 }} /> : 'Немає зображення'}
                      </div>
                    )
                  },
                  { title: <div style={{ textAlign: 'center' }}>Назва</div>, dataIndex: 'name', key: 'name' },
                  { 
                    title: (
                      <div style={{ textAlign: 'center' }}>
                        <Select
                          mode="multiple"
                          value={vidKormuFilter}
                          onChange={handleVidKormuFilterChange}
                          placeholder="Вид корму"
                          style={{ width: 200 }}
                          allowClear
                        >
                          {vidKormuOptions.map(option => (
                            <Option key={option.id} value={option.id}>{option.label}</Option>
                          ))}
                        </Select>
                      </div>
                    ),
                    dataIndex: 'vidKormu',
                    key: 'vidKormu',
                    render: (vidKormu) => vidKormuOptions.find(option => option.id === vidKormu)?.label || 'Невідомий вид'
                  },
                  { title: <div style={{ textAlign: 'center' }}>Популярність</div>, dataIndex: 'count', key: 'count', sorter: (a, b) => a.count - b.count },
                ]}
                pagination={false}
              />
            </Space>
          )}
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Ant Design ©{new Date().getFullYear()} Created by Ant UED
        </Footer>
      </Layout>
    </Layout>
  );
};

export default StatsPage;
