// client/src/pages/SurveyFlowTest.js

import React from 'react';
import SurveyFlow from './SurveyFlow';

const prefilledAnswers = {
  name: { questionValue: 'name', value: 'Пуся' },
  age: { questionValue: 'age', value: '1-7' },
  breed: { questionValue: 'breed', value: 'outbred' },
  furType: { questionValue: 'furType', value: 'shortHaired' },
  furColor: { questionValue: 'furColor', value: 'colorful' },
  matting: { questionValue: 'matting', value: 'no' },
  bodyType: { questionValue: 'bodyType', value: 'normal' },
  weight: { questionValue: 'weight', value: 10 },
  activity: { questionValue: 'activity', value: 'slowlyActive' },
  gender: { questionValue: 'gender', value: 'female' },
  neutered: { questionValue: 'neutered', value: 'yes' },
  chronicIllness: { questionValue: 'chronicIllness', value: 'no' },
  dentalIssues: { questionValue: 'dentalIssues', value: 'no' },
  digestiveIssues: { questionValue: 'digestiveIssues', value: ['constipation'] },
  allergies: { questionValue: 'allergies', value: 'yes' },
  allergySymptoms: { questionValue: 'allergySymptoms', value: ['scratching'] },
  dietType: { questionValue: 'dietType', value: ['dryFood'] },
  feedingFrequency: { questionValue: 'feedingFrequency', value: 'freeAccess' },
  appetite: { questionValue: 'appetite', value: 'veryPicky' },
  tastePreferences: { questionValue: 'tastePreferences', value: ['meat'] },
  waterIntake: { questionValue: 'waterIntake', value: '100-150' },
  waterType: { questionValue: 'waterType', value: 'filteredWater' },
  outdoorAccess: { questionValue: 'outdoorAccess', value: 'no' },
  playComplexAccess: { questionValue: 'playComplexAccess', value: 'yes' },
  numberOfCats: { questionValue: 'numberOfCats', value: '1' },
  // Видаляємо email з попередньо заповнених відповідей
};

const SurveyFlowTest = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Тестова сторінка SurveyFlow</h1>
      <SurveyFlow
        prefilledAnswers={prefilledAnswers}
        testMode={true}
        petTypeOverride="cat" // Примусово встановлюємо тип хвостика
      />
    </div>
  );
};

export default SurveyFlowTest;
