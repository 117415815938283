// client/src/routes.js

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthPage } from './pages/AuthPage';
import IngredientGeneratorPage from './pages/IngredientGeneratorPage';
import AddCompositionPage from './pages/AddCompositionPage';
import ProgressPage from './pages/ProgressPage';
import HomePage from './pages/HomePage';
import SurveyFlow from './pages/SurveyFlow';
import StatsPage from './pages/StatsPage';
import SurveyFlowTest from './pages/SurveyFlowTest';

export const useRoutes = (isAuthenticated) => {
    if (isAuthenticated) {
        return (
            <Routes>
                <Route path="home" element={<HomePage />} />
                <Route path="ingredient-generator" element={<IngredientGeneratorPage />} />
                <Route path="add-composition/:articleNumber?" element={<AddCompositionPage />} />
                <Route path="progress" element={<ProgressPage />} />
                <Route path="survey" element={<SurveyFlow />} />
                <Route path="stats" element={<StatsPage />} />
                <Route path="test-survey" element={<SurveyFlowTest />} /> {/* Додаємо маршрут сюди */}
                <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
        );
    }

    // Неавторизовані маршрути
    return (
        <Routes>
            <Route path="/" element={<AuthPage />} />
            <Route path="survey" element={<SurveyFlow />} />
            <Route path="test-survey" element={<SurveyFlowTest />} /> {/* Маршрут також тут */}
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};
