//client/src/pages/SurveyResults.js

import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import GroupedProducts from '../components/GroupedProducts';
import { generateCatRecommendationText } from '../utils/catRecommendationTextLogic';
import { generateDogRecommendationText } from '../utils/dogRecommendationTextLogic';

const SurveyResults = ({ answers, petType, email }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  // Функція для отримання рекомендацій
  const fetchRecommendations = async (answers, petType, email) => {
    try {
      const response = await fetch('/api/cat-products', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ answers, petType, email }),
      });
  
      if (!response.ok) {
        throw new Error('Помилка під час отримання рекомендацій');
      }
  
      const data = await response.json();
      console.log('Рекомендовані продукти:', data.recommendedProducts);
      return data.recommendedProducts;
    } catch (error) {
      console.error('Помилка:', error.message);
      return [];
    }
  };  

  // Виклик функції fetchRecommendations під час завантаження компонента
  useEffect(() => {
    const getRecommendations = async () => {
      setLoading(true);
      const recommendedProducts = await fetchRecommendations(answers, petType, email);
      setProducts(recommendedProducts);
      setLoading(false);
    };

    getRecommendations();
  }, [answers, petType, email]);

  // Перевіряємо чи answers визначений перед використанням
  if (!answers) {
    console.warn('SurveyResults: відповіді не визначені.');
    return <p>Відповіді не були знайдені, будь ласка, спробуйте знову.</p>;
  }

  // Генеруємо текст рекомендацій на основі відповідей та типу тварини
  let recommendationText = "";

  console.log('SurveyResults: отримані відповіді:', answers);
  if (petType === 'cat') {
    recommendationText = generateCatRecommendationText(answers);
    console.log('SurveyResults: текст рекомендацій для кота:', recommendationText);
  } else if (petType === 'dog') {
    recommendationText = generateDogRecommendationText(answers);
    console.log('SurveyResults: текст рекомендацій для собаки:', recommendationText);
  }

  console.log('SurveyResults: рекомендаційний текст для відображення:', recommendationText);

  return (
    <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
      {/* Блок з рекомендаціями */}
      <Card 
        title={<div style={{ fontSize: '18px', borderRadius: '8px 8px 0 0' }}>Рекомендації</div>} 
        bordered={true} 
        headStyle={{ borderRadius: '8px 8px 0 0' }}
        style={{ 
          marginBottom: '20px', 
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif", 
          lineHeight: '1.6',
          borderRadius: '8px',
          borderColor: '#ddd',
          borderWidth: '1px',
          borderStyle: 'solid'
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: recommendationText }} />
      </Card>

      {/* Блок з рекомендованими товарами */}
      <Card 
        title={<div style={{ fontSize: '18px', borderRadius: '8px 8px 0 0' }}>Рекомендовані товари</div>} 
        bordered={true} 
        headStyle={{ borderRadius: '8px 8px 0 0' }}
        style={{ 
          fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif", 
          lineHeight: '1.6',
          borderRadius: '8px',
          borderColor: '#ddd', 
          borderWidth: '1px',
          borderStyle: 'solid'
        }}
      >
        {loading ? (
          <p>Завантаження рекомендацій...</p>
        ) : products.length > 0 ? (
          <GroupedProducts products={products} />
        ) : (
          <p>Немає доступних товарів за вашими запитами.</p>
        )}
      </Card>
    </div>
  );
};

export default SurveyResults;
