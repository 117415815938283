//client/src/components/questions/DogQuestions.js

export const dogQuestions = [
    { id: 1, question: "Кличка", type: "text", questionValue: "name" },
    { id: 2, question: "Вік", type: "radio", options: [
      { label: "0-12 міс", value: "0-12" },
      { label: "1-7 років", value: "1-7" },
      { label: "7+ років", value: "7+" }
      ], questionValue: "age"
    },
    { id: 3, question: "Порода", type: "select", options: [
      { label: "Маленькі породи (до 10 кг): чихуахуа, йоркширський тер'єр, померанський шпіц, джек-рассел-тер'єр, мопс, французький бульдог, ши-тцу, пекінес", value: "MINI" },
      { label: "Середні породи (10-25 кг): бігль, коргі, шелті, кокер-спанієль, басенджі, бордоський дог, шарпей, шотландський тер'єр", value: "MEDIUM" },
      { label: "Великі породи (25-45 кг): лабрадор-ретрівер, німецька вівчарка, золотистий ретрівер, акіта-іну, доберман, далматинець, ротвейлер, боксер", value: "MAXI" },
      { label: "Гігантські породи (понад 45 кг): сенбернар, мастиф, ньюфаундленд, ірландський вовкодав, дог, леонбергер, кавказька вівчарка, алабай", value: "GIANT" },
      ], questionValue: "breed"
    },
    { id: 4, question: "Колір шерсті", type: "radio", options: [
      { label: "білий", value: "white" },
      { label: "чорний", value: "black" },
      { label: "кольоровий", value: "colorful" },
      { label: "рудий", value: "ginger" }
    ], questionValue: "furColor"
    },
    { id: 5, question: "Тип шерсті", type: "checkbox", options: [
      { label: "Пряма", value: "straight" },
      { label: "Кучерява", value: "curly" },
      { label: "Треба стригти", value: "needsClipping" },
      { label: "Жорсткошерста", value: "wireHaired" }
    ], questionValue: "furTexture"
    },
    { id: 6, question: "Тип статури за 5-бальною шкалою", type: "radio", options: [
      { 
        label: "виснажений", 
        value: "emaciated", 
        image: "/images/DogQuestions/dog1.svg"
      },
      { 
        label: "худий", 
        value: "thin", 
        image: "/images/DogQuestions/dog2.svg"
      },
      { 
        label: "нормальний", 
        value: "normal", 
        image: "/images/DogQuestions/dog3.svg"
      },
      { 
        label: "вгодований", 
        value: "overweight", 
        image: "/images/DogQuestions/dog4.svg"
      },
      { 
        label: "ожиріння", 
        value: "obesity", 
        image: "/images/DogQuestions/dog5.svg"
      }
      ], questionValue: "bodyType"
    },
    { id: 7, question: "Вага песика (в кг)", type: "slider", questionValue: "weight" },
    { id: 8, question: "Як ви оцінюєте рівень активності вашого песика протягом дня?", type: "select", options: [
      { label: "Дуже активний (бігає, грається більше 6 годин на день)", value: "veryActive" },
      { label: "Середньо активний (грається та бігає 3–6 годин на день)", value: "mediumActive" },
      { label: "Малорухливий (грається та бігає 1–3 години на день)", value: "slowlyActive" },
      { label: "Майже неактивний (переважно відпочиває, грається менше 1 години на день)", value: "inActive" }
      ], questionValue: "activity"
    },
    { id: 9, question: "Стать", type: "radio", options: [
      { label: "Самка", value: "female" },
      { label: "Самець", value: "male" }
    ], questionValue: "gender"
    },
    { id: 10, question: "Тваринка стерилізована/кастрована?", type: "radio", options: [
        { label: "Так", value: "yes" },
        { label: "Ні", value: "no" }
      ], questionValue: "neutered"
    },
    { id: 11, question: "Чи народжує собачка?", type: "radio", options: [
      { label: "ні, ніколи", value: "never" },
      { label: "так, 3 рази на рік", value: "3_times" },
      { label: "так, 2 рази на рік", value: "2_times" },
      { label: "так, 1 раз на рік", value: "1_time" },
      { label: "так, рідше одного разу на рік", value: "rarely" },
      { label: "ні, але плануємо вагітність", value: "plan" }
    ], questionValue: "birthFrequency", condition: (answers) => answers.gender?.value === 'female' && answers.neutered?.value === 'no'
    },
    { id: 12, question: "Чи приймає (name) протизаплідні?", type: "radio", options: [
      { label: "так", value: "yes" },
      { label: "ні", value: "no" }
    ], questionValue: "contraceptiveUse",
    condition: (answers) => answers.gender?.value === 'female' && answers.neutered?.value === 'no' && answers.birthFrequency?.value === 'never'
    },
    { id: 13, question: "Чи є хронічні захворювання?", type: "radio", options: [
      { label: "Так", value: "yes" },
      { label: "Ні", value: "no" }
    ], questionValue: "chronicIllness"
    },
    { id: 14, question: "Чи приймає (name) ліки для підтримки стану?", type: "checkbox", options: [
      { label: "Інсулін", value: "insulin" },
      { label: "Нестероїдні протизапальні препарати", value: "nsaids" },
      { label: "Тироксин", value: "thyroxin" },
      { label: "Стероїди", value: "steroids" },
      { label: "Інші", value: "other" },
      { label: "Ні", value: "no" }
    ], questionValue: "medications", 
    condition: (answers) => answers.chronicIllness?.value === 'yes' 
    },
    { id: 15, question: "Чи є проблеми із зубами або яснами?", type: "radio", options: [
        { label: "Так", value: "yes" },
      { label: "Ні", value: "no" }
    ], questionValue: "dentalIssues"
    },
    { id: 16, question: "Чи бувають проблеми з травленням?", type: "checkbox", options: [
      { label: "Закрепи", value: "constipation" },
      { label: "Проноси", value: "diarrhea" },
      { label: "Блювота", value: "vomiting" },
      { label: "Ні", value: "no" }
    ], questionValue: "digestiveIssues"
    },
    { id: 17, question: "Чи є алергії або непереносимість інгредієнтів?", type: "radio", options: [
      { label: "Так", value: "yes" },
      { label: "Ні", value: "no" }
    ], questionValue: "allergies"
    },
    { id: 18, question: "Симптоми алергії", type: "checkbox", options: [
      { label: "Свербіж", value: "itching" },
      { label: "Облисіння", value: "hairLoss" },
      { label: "Червоні плями", value: "redSpots" },
      { label: "Розчухування", value: "scratching" },
      { label: "Запалення вух", value: "earInflammation" },
      { label: "Течуть очі", value: "wateryEyes" }
    ], questionValue: "allergySymptoms", 
    condition: (answers) => answers.allergies?.value === 'yes' 
    },
    { id: 19, question: "Тип харчування", type: "checkbox", options: [
      { label: "Сухий корм", value: "dryFood" },
      { label: "Вологий корм", value: "wetFood" },
      { label: "Домашня їжа", value: "homemade" },
      { label: "Залишки зі столу", value: "tableScraps" }
    ], questionValue: "dietType"
    },
    { id: 20, question: "Частота годування", type: "radio", options: [
      { label: "Раз на день", value: "onceADay" },
      { label: "Двічі на день", value: "twiceADay" },
      { label: "Більше", value: "moreThanTwice" },
      { label: "Їжа у постійному доступі", value: "freeAccess" }
    ], questionValue: "feedingFrequency"
    },
    { id: 21, question: "Як би ви оцінили апетит хвостика?", type: "radio", options: [
      { label: "Хороший їдець", value: "goodEater" },
      { label: "Іноді вередує", value: "pickySometimes" },
      { label: "Сильно вибагливий у їжі", value: "veryPicky" }
    ], questionValue: "appetite"
    },
    { id: 22, question: "Смакові вподобання (name)", type: "checkbox", options: [
      { label: "Без зернових", value: "grainFree" },
      { label: "Без курочки", value: "withoutChicken" },
      { label: "М'яско", value: "meat" },
      { label: "Рибка", value: "fish" }
    ], questionValue: "tastePreferences"
    },
    { id: 23, question: "Кількість води, що тваринка випиває на день", type: "radio", options: [
      { label: "50-100 мл", value: "50-100" },
      { label: "100-300 мл", value: "100-300" },
      { label: "300-1000 мл", value: "300-1000" },
      { label: "більше 1 л", value: "1000" },
      { label: "Не відслідковую", value: "notTracking" }
    ], questionValue: "waterIntake"
  },
  { id: 24, question: "Тип води", type: "radio", options: [
      { label: "З-під крана", value: "tapWater" },
      { label: "Фільтрована", value: "filteredWater" },
      { label: "Покупна", value: "bottledWater" },
      { label: "Спеціальна вода для собак", value: "specialDogWater" },
      { label: "Зі свердловини", value: "wellWater" }
    ], questionValue: "waterType"
  },
  { id: 25, question: "Чи має (name) прболеми з поведінкою?", type: "multipleSelect", options: [
      { label: "Агресія", value: "aggression" },
      { label: "Тривожність", value: "anxiety" },
      { label: "Гіперактивність або надмірна збудженість", value: "hyperactivity" },
      { label: "Надмірне гавкання", value: "excessiveBarking" },
      { label: "Деструктивна поведінка (гризе меблі, речі)", value: "destructiveBehavior" },
      { label: "Схильність до втеч", value: "escaping" },
      { label: "Відмова від навчань", value: "disobedience" },
      { label: "Інші проблеми", value: "otherBehaviorIssues" },
      { label: "Ні", value: "no" }
    ], questionValue: "behavioural"
  },
  { id: 26, question: "Чому ви б хотіли приділити особливу увагу?", type: "multipleSelect", options: [
      { label: "Ріст", value: "growth" },
      { label: "Поліпшення шерсті", value: "furImprovement" },
      { label: "Контроль ваги", value: "weightControl" },
      { label: "Підтримка суглобів", value: "jointSupport" },
      { label: "Підтримка серця", value: "heartSupport" },
      { label: "Старіння", value: "aging" },
      { label: "Пологи, годування", value: "pregnancyLactation" }
  ], questionValue: "specialFocus"
  },
  { id: 27, question: "Який бренд (name) смакує зараз?", type: "multipleSelect", options: [
      { label: "Royal Canin", value: "RoyalCanin" },
      { label: "Brit Care", value: "BritCare" },
      { label: "Brit Premium", value: "BritPremium" },
      { label: "Josera", value: "Josera" },
      { label: "Optimeal", value: "Optimeal" },
      { label: "Purina Pro Plan", value: "PurinaProPlan" },
      { label: "Savory", value: "Savory" },
      { label: "Acana", value: "Acana" },
      { label: "Home Food", value: "HomeFood" },
      { label: "Carnilove", value: "Carnilove" },
      { label: "Nature's Protection", value: "NaturesProtection" },
      { label: "Oven-Baked", value: "OvenBaked" },
      { label: "Hill's", value: "Hills" },
      { label: "Farmina", value: "Farmina" },
      { label: "Dog Chow", value: "DogChow" },
      { label: "Bavaro", value: "Bavaro" },
      { label: "Advance", value: "Advance" },
      { label: "Almo Nature", value: "AlmoNature" },
      { label: "Alpha Spirit", value: "AlphaSpirit" },
      { label: "Araton", value: "Araton" },
      { label: "Baskerville", value: "Baskerville" },
      { label: "Belcando", value: "Belcando" },
      { label: "Bon Appetit", value: "BonAppetit" },
      { label: "BonaCibo", value: "BonaCibo" },
      { label: "Brekkies", value: "Brekkies" },
      { label: "Brit Fresh", value: "BritFresh" },
      { label: "CooKing", value: "CooKing" },
      { label: "Criss", value: "Criss" },
      { label: "Dolina Noteci", value: "DolinaNoteci" },
      { label: "Dr. Clauder’s", value: "DrClauders" },
      { label: "Exclusion", value: "Exclusion" },
      { label: "Gemon", value: "Gemon" },
      { label: "Green Petfood", value: "GreenPetfood" },
      { label: "Half&Half", value: "HalfHalf" },
      { label: "Hubertus Gold", value: "HubertusGold" },
      { label: "Isegrim", value: "Isegrim" },
      { label: "Mera", value: "Mera" },
      { label: "Miocane", value: "Miocane" },
      { label: "Monge", value: "Monge" },
      { label: "Mystic", value: "Mystic" },
      { label: "Natyka", value: "Natyka" },
      { label: "Nutram", value: "Nutram" },
      { label: "Nutrican", value: "Nutrican" },
      { label: "Orijen", value: "Orijen" },
      { label: "Ownat", value: "Ownat" },
      { label: "Profine", value: "Profine" },
      { label: "Reflex Plus", value: "ReflexPlus" },
      { label: "Schesir", value: "Schesir" },
      { label: "Taste of the Wild", value: "TasteoftheWild" },
      { label: "Клуб 4 лапи", value: "Club4Paws" },
      { label: "Інше", value: "Other" }
  ], questionValue: "brand"
  },
  { id: 28, question: "Введіть ваш email для отримання рекомендацій з харчування", type: "text", questionValue: "email", rules: [
      {
        required: true,
        message: 'Будь ласка, введіть ваш email!',
      },
      {
        type: 'email',
        message: 'Введіть правильний email!',
      },
    ]
  }
];

export default dogQuestions;