//client/src/utils/catRecommendationTextLogic.js

export function generateCatRecommendationText(answers, name) {
  console.log('generateCatRecommendationText: отримані відповіді:', answers);
  console.log('generateCatRecommendationText: передане ім\'я:', name);

  // Якщо ім'я не передане або порожнє, використовуємо "ваш котик"
  const finalName = answers.name?.value || name || 'ваш котик';
  console.log('generateCatRecommendationText: остаточне ім\'я для використання:', finalName);

  let text = '';

  // Блок 1. Рекомендації щодо харчування
  let foodRecommendations = '';

  // Вік
  if (answers.age?.value === '0-12') {
    foodRecommendations += `<div>Оскільки ${finalName} ще маленький, йому потрібно особливе харчування, що сприяє зростанню і розвитку.</div>`;
  } else if (answers.age?.value === '7+') {
    foodRecommendations += `<div>${finalName} досяг зрілого віку, тому важливо забезпечити його харчуванням, яке підтримує здоров'я і активність.</div>`;
  }

  // Стерилізація
  if (answers.neutered?.value === 'yes') {
    foodRecommendations += `<div>Оскільки ${finalName} кастрований/стерилізований, ми пропонуємо корм, який допомагає контролювати вагу та підтримувати здоров'я сечовидільної системи.</div>`;
  }

  // Тип статури
  if (answers.bodyType?.value === 'emaciated' || answers.bodyType?.value === 'thin') {
    foodRecommendations += `<div>${finalName} має недостатню вагу. Для підвищення апетиту та покращення поїдання корму, радимо додати до раціону м'ясний топінг із розрахунку 2-3% від загальної ваги порції.</div>`;
  } if (answers.bodyType?.value === 'overweight' || answers.bodyType?.value === 'obesity') {
    foodRecommendations += `<div>${finalName} має надлишкову вагу. Важливо контролювати порції та дотримуватись регулярного режиму годування. Також використовуйте іграшки, які стимулюють рух.</div>`;
  } else if (answers.bodyType?.value === 'normal') {
    foodRecommendations += `<div>${finalName} в ідеальній формі! Продовжуйте дотримуватись рекомендацій з харчування.</div>`;
  }

  // Довжина шерсті
  if (answers.furType?.value === 'longHaired') {
    foodRecommendations += `<div>Для котів з довгою шерстю, таких як ${finalName}, важливо забезпечити спеціальне харчування, що сприяє зменшенню утворення ковтунів і підтримці блиску шерсті.</div>`;
  }

  // Алергії
  if (answers.allergies?.value === 'yes') {
    foodRecommendations += `<div>Для котиків-алергіків, таких як ${finalName}, у HOME FOOD є корми з гіпоалергенними інгредієнтами.</div>`;
  }

  // Додаємо блок тільки якщо є контент
  if (foodRecommendations) {
    text += `<div style="display: flex;">
              <img src="/images/recommendation/food.svg" alt="Food" style="width: 60px; height: 60px; margin-right: 10px;" />
              <div><strong style="color: #002d5a;">Рекомендації щодо харчування</strong>${foodRecommendations}</div>
            </div>`;
  }

  // Блок 2: Рекомендації щодо пиття
  let waterRecommendations = '';

  // Кількість води на день
  if (answers.weight?.value && answers.waterIntake?.value) {
    const weight = parseFloat(answers.weight.value);
    const waterIntake = answers.waterIntake.value;

    console.log('generateCatRecommendationText: вага котика:', weight);
    console.log('generateCatRecommendationText: кількість води:', waterIntake);

    // Розрахунок ідеальної кількості води
    const idealMinWater = weight * 30; // Мінімум 30 мл на кг ваги
    const idealMaxWater = weight * 50; // Максимум 50 мл на кг ваги

    // Визначаємо обраний діапазон кількості води
    let minWaterIntake = 0;
    let maxWaterIntake = 0;

    switch (waterIntake) {
      case '30-50':
        minWaterIntake = 30;
        maxWaterIntake = 50;
        break;
      case '50-100':
        minWaterIntake = 50;
        maxWaterIntake = 100;
        break;
      case '100-150':
        minWaterIntake = 100;
        maxWaterIntake = 150;
        break;
      case '150-200':
        minWaterIntake = 150;
        maxWaterIntake = 200;
        break;
      case '200-250':
        minWaterIntake = 200;
        maxWaterIntake = 250;
        break;
      case '250-300':
        minWaterIntake = 250;
        maxWaterIntake = 300;
        break;
      default:
        console.warn('generateCatRecommendationText: невідоме значення для waterIntake:', waterIntake);
        break;
    }

    // Формуємо текст рекомендації на основі кількості води
    if (maxWaterIntake < idealMinWater) {
      waterRecommendations += `<div>${finalName} п'є недостатньо води, радимо додавати топінг (2-3 мл на 50 мл води) для стимуляції пиття.</div>`;
    } else if (minWaterIntake >= idealMinWater && maxWaterIntake <= idealMaxWater) {
      waterRecommendations += `<div>${finalName} п'є ідеальну кількість води. Продовжуйте стежити за водним балансом.</div>`;
    } else if (minWaterIntake > idealMaxWater) {
      waterRecommendations += `<div>${finalName} п'є забагато води, радимо звернутись до ветеринара для консультації.</div>`;
    }
  } else {
    console.warn('generateCatRecommendationText: дані для ваги або кількості води не визначені.');
  }

  // Тип води
  if (answers.waterType?.value) {
    switch (answers.waterType.value) {
      case 'filteredWater':
        waterRecommendations += "<div>Фільтрована вода — хороший варіант. Зверніть увагу на спеціальну воду для котиків.</div>";
        break;
      case 'bottledWater':
        waterRecommendations += "<div>Покупна вода — хороший варіант. Зверніть увагу на спеціальну воду для котиків.</div>";
        break;
      case 'specialCatWater':
        waterRecommendations += "<div>Спеціальна вода для котів — відмінний вибір для підтримки здоров'я вашого котика.</div>";
        break;
      case 'wellWater':
        waterRecommendations += "<div>Вода зі свердловини може містити мінерали та бактерії, тому варто провести аналіз перед споживанням. Зверніть увагу на спеціальну воду для котиків.</div>";
        break;
      case 'tapWater':
        waterRecommendations += "<div>Рекомендуємо звернути увагу на спеціальну воду для котиків.</div>";
        break;
      default:
        break;
    }
  }

  if (waterRecommendations) {
    text += `<div style="margin-top: 20px; display: flex;">
              <img src="/images/recommendation/water.svg" alt="Water" style="width: 60px; height: 60px; margin-right: 10px;" />
              <div><strong style="color: #002d5a;">Рекомендації щодо пиття</strong>${waterRecommendations}</div>
            </div>`;
  }

  // Блок 3: Поради по облаштуванню
  let toyRecommendations = '';

  // Активність
  if (answers.activity?.value === 'veryActive' || answers.activity?.value === 'mediumActive') {
    toyRecommendations += `<div>Забезпечте достатньо фізичної та ментальної стимуляції через ігри, головоломки та простір для лазіння, щоб спрямувати енергію у позитивне русло.</div>`;
  } else if (answers.activity?.value === 'slowlyActive' || answers.activity?.value === 'inActive') {
    toyRecommendations += `<div>Заохочуйте кота до руху за допомогою інтерактивних ігор та стежте за його вагою, щоб підтримувати здоровий стан, звертаючи увагу на можливі проблеми зі здоров'ям.</div>`;
  }

  // Доступ до іграшок та кігтеточок
  if (answers.playComplexAccess?.value === 'yes') {
    toyRecommendations += "<div>Іграшки та кігтеточки дуже важливі для розвитку котика. Однак не залишайте котика наодинці з іграшками, які він може проковтнути, особливо з пір'ям. Переконайтеся, що всі іграшки є безпечними.</div>";
  } else {
    toyRecommendations += "<div>Рекомендуємо придбати кігтеточку та безпечні іграшки для вашого котика, щоб забезпечити йому активність та зменшити ризик пошкодження меблів.</div>";
  }

  if (toyRecommendations) {
    text += `<div style="margin-top: 20px; display: flex;">
              <img src="/images/recommendation/toy.svg" alt="Toy" style="width: 60px; height: 60px; margin-right: 10px;" />
              <div><strong style="color: #002d5a;">Поради по облаштуванню котокуточку</strong>${toyRecommendations}</div>
            </div>`;
  }

  // Блок 4: Застереження
  let alertWarnings = '';

  // Протизаплідні
  if (answers.contraceptiveUse?.value === 'yes') {
    alertWarnings += "<div>Стерилізація, на відміну від застосування протизаплідних засобів, є більш гуманним і довготривалим методом регулювання чисельності тварин. Вона не потребує постійного медичного втручання і не викликає змін у гормональному фоні, що знижує ризик ускладнень і захворювань, таких як піометра у самок або пухлини статевих органів. Крім того, стерилізація допомагає запобігти неконтрольованому розмноженню та появі безпритульних тварин, що покращує добробут популяції загалом.</div>";
  }

  // Самовигул
  if (answers.outdoorAccess?.value === 'yes') {
    alertWarnings += "<div>Самовигул є небезпечним для котиків через ризики травм, інфекцій і контактів з агресивними тваринами. Рекомендуємо забезпечити безпечний простір для вашого котика у приміщенні.</div>";
  }

  // Тип води
  if (answers.waterType?.value?.includes('tapWater')) {
    alertWarnings += "<div>Котам не рекомендується пити воду з-під крана, оскільки вона може містити хлор, важкі метали або інші домішки, що можуть бути шкідливими для їхнього здоров'я. Це може призвести до проблем із травленням, сечовою системою або шкірою, тому краще давати котам очищену, фільтровану або спеціальну воду для котів.</div>";
  }

  // Тип харчування
  if (answers.dietType?.value?.includes('tableScraps')) {
    alertWarnings += "<div>Залишки зі столу можуть бути дуже шкідливими для вашого котика через вміст спецій, солі та інших небезпечних інгредієнтів. Ми не рекомендуємо годувати котика залишками їжі.</div>";
  }
  if (answers.dietType?.value?.includes('wetFood')) {
    alertWarnings += "<div>Вологий корм може бути незбалансованим, тому важливо перевіряти його склад, щоб забезпечити всі необхідні поживні речовини для вашого котика.</div>";
  }

  if (alertWarnings) {
    text += `<div style="margin-top: 20px; display: flex;">
              <img src="/images/recommendation/attention1_cat.svg" alt="Alert" style="width: 60px; height: 60px; margin-right: 10px;" />
              <div><strong style="color: #002d5a;">Застереження</strong>${alertWarnings}</div>
            </div>`;
  }

  console.log('generateCatRecommendationText: згенерований текст:', text);
  return text;
}

// Відображення тексту з HTML-тегами
export function RecommendationText({ text }) {
  return <div dangerouslySetInnerHTML={{ __html: text }} />;
}
