// home/servers/feedcraft/client/src/components/GroupedProducts.js

import React from 'react';
import ProductCard from './ProductCard';

const GroupedProducts = ({ products }) => {
  if (!products || products.length === 0) {
    return <p>Немає доступних товарів для відображення.</p>;
  }

  // Групування товарів по parent_article або id
  const groupedProducts = products.reduce((acc, product) => {
    const parentArticle = product.parent_article || product.id;

    if (!acc[parentArticle]) {
      acc[parentArticle] = [];
    }

    acc[parentArticle].push(product);
    return acc;
  }, {});

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '0 20px', // Виставляємо padding для симетричних відступів
      }}
    >
      {Object.keys(groupedProducts).map((parentArticle) => {
        const group = groupedProducts[parentArticle];
        const mainProduct = group.find((product) => product.id === product.parent_article) || group[0];
        
        // Використовуємо Set для уникнення дублювання ваги
        const availableWeights = Array.from(
          new Set(
            group
              .map((prod) => ({
                id: prod.id,
                value: prod.vaga || 'Невідомо',
              }))
              .filter((weight) => weight.value !== 'Невідомо')
              .map((weight) => weight.value) // Отримуємо лише унікальні ваги
          )
        ).map((value, index) => ({ id: index, value }));

        return (
          <div
            key={mainProduct.id}
            style={{
              width: 'calc(33.333% - 20px)', // Ширина для великих екранів
              marginBottom: '20px',
              boxSizing: 'border-box',
            }}
          >
            <ProductCard product={mainProduct} availableWeights={availableWeights} />
          </div>
        );
      })}
      <style jsx>{`
        @media (max-width: 768px) {
          div[style*='calc(33.333%'] {
            width: calc(50% - 20px); // 2 картки в рядок для мобільних пристроїв
          }
        }
      `}</style>
    </div>
  );
};

export default GroupedProducts;
