//client/src/controllers/recommendation/catRecommendationLogic.js

export function calculateCatRecommendations(answers, noResultsFlag = false) {
  const characteristics = {};

  // Вік
  if (answers.age?.value === '0-12') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 7]; // id для "Для малюків, для вагітних та годуючих самок"
    characteristics['vk'] = 4; // id для "Кошенята"
  } else if (answers.age?.value === '1-7') {
    characteristics['dljaKogo'] = 1; // id для "Котикам"
  } else if (answers.age?.value === '7+') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 41]; // id для "Для тварин старше 7 років"
    characteristics['dljaKogo'] = 1; // id для "Котикам"
  }

  // Порода
    if (answers.breed?.value === 'hairless') {
      characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 25]; // id для "Для екзотичних порід"
    } else if (answers.breed?.value === 'exotic') {
      characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 25]; // id для "Для екзотичних порід"
    } else if (answers.breed?.value === 'longhair') {
      characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 30]; // id для "Для довгошерстих"
    }

  // Колір шерсті
  if (answers.furColor?.value === 'white') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 34]; // id для "Для тварин з білою шерстю"
  }

  // Довжина шерсті
  if (answers.furType?.value === 'longHaired') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 30, 12, 17]; // id для "Для виведення шерсті зі шлунку" та "Здоров’я шкіри та блиск шерсті" та "Для довгошерстих"
  } else if (answers.furType?.value === 'shortHaired') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 17]; // id для "Здоров’я шкіри та блиск шерсті"
  }

  // Схильність до утворення ковтунів
  if (answers.matting?.value === 'yes') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 30, 17]; // id для "Для довгошерстих" та "Здоров’я шкіри та блиск шерсті"
  }

  // Тип статури
  if (answers.bodyType?.value === 'emaciated' || answers.bodyType?.value === 'thin') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 36, 38]; // id для "Для набору ваги" та "Для відновлення після хвороб, стресів і операцій"
  } else if (answers.bodyType?.value === 'overweight' || answers.bodyType?.value === 'obesity') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 39]; // id для "Для зниження ваги"
  }

  // Активність
  if (answers.activity?.value === 'veryActive' || answers.activity?.value === 'mediumActive') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 3]; // id для "Для активних"
  }

  // Стерилізований/кастрований
  if (answers.neutered?.value === 'yes') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 8, 22]; // id для "Для кастрованих/стерилізованих" та "Для профілактики сечокам’яної хвороби"
  } else if (answers.neutered?.value === 'yes') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 22]; // id для "Для профілактики сечокам’яної хвороби"
  }

  // Народжує кішка
  if (answers.birthFrequency?.value?.includes('3_times') ||
      answers.birthFrequency?.value?.includes('2_times') ||
      answers.birthFrequency?.value?.includes('1_time')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 7, 23]; // id для "Для малюків, для вагітних та годуючих самок" та "Підготовка до пологів"
  } else if (answers.birthFrequency?.value === 'rarely') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 43]; // id для "Для покращення фертильності"
  }

  // Хронічні захворювання
  //if (answers.chronicIllness?.value === 'yes') {
  //  characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 42]; // id для "Для підвищення імунітету"
  //}

  // Захворювання систем
  if (answers.medications?.value === 'nervous') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 38, 44, 21, 46]; // id для "Для відновлення після хвороб, стресів і операцій" та "Для підтримки та покращення зору" та "Для зміцнення серцево-судинної системи" та "Для підтримки когнітивних функцій"
  } else if (answers.medications?.value === 'heart') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 21]; // id для "Для зміцнення серцево-судинної системи"
  } else if (answers.medications?.value === 'gastro') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 45, 16, 40, 47, 11]; // id для "При порушенні функцій печінки" та "Для нормалізації роботи ШКТ" та "Для посилення перистальтики" та "Для підтримки здоров'я при діабеті" та "При чутливому травленні"
  } else if (answers.medications?.value === 'urinary') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 22, 43, 23]; // id для "Для профілактики сечокам’яної хвороби" та "Для покращення фертильності" та "Для підготовки до пологів"
  } else if (answers.medications?.value === 'skeletal') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 33]; // id для "Для підтримки опорно-рухового апарату"
  } else if (answers.medications?.value === 'other') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 42]; // id для "Для підвищення імунітету"
  }

  // Проблеми з травленням
  if (answers.digestiveIssues?.value === 'constipation') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 40, 16]; // id для "Для посилення перистальтики" та "Для нормалізації роботи ШКТ"
  } else if (answers.digestiveIssues?.value === 'diarrhea') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 16, 11]; // id для "Для нормалізації роботи ШКТ" та "При чутливому травленні"
  } else if (answers.digestiveIssues?.value === 'vomiting') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 16, 11]; // id для "Для нормалізації роботи ШКТ" та "При чутливому травленні"
  }

  // Алергії/непереносимість
  if (answers.allergies?.value === 'yes') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 1, 11, 37]; // id для "При схильності до алергій" та "При чутливому травленні" та "При чутливій шкірі"
  }

  // Апетит
  if (answers.appetite?.value?.includes('pickySometimes')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 10, 35]; // id для "Для вибагливих" та "Для покращення апетиту"
  }
  if (answers.appetite?.value?.includes('veryPicky')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 10, 35]; // id для "Для вибагливих" та "Для покращення апетиту"
  }

  // Смакові вподобання
  if (answers.tastePreferences?.value?.includes('grainFree')) {
    characteristics['tipKormu'] = [...(characteristics['tipKormu'] || []), 1]; // id для "Беззерновий"
  }
  if (answers.tastePreferences?.value?.includes('withoutChicken')) {
    characteristics['tipKormu'] = [...(characteristics['tipKormu'] || []), 5]; // id для "Без курочки"
  }
  if (answers.tastePreferences?.value?.includes('meat')) {
    characteristics['tipKormu'] = [...(characteristics['tipKormu'] || []), 3]; // id для "На основі м'яса"
  }
  if (answers.tastePreferences?.value?.includes('fish')) {
    characteristics['tipKormu'] = [...(characteristics['tipKormu'] || []), 4]; // id для "На основі риби"
  }

  // Вода
  if (['30-50', '50-100', '100-150', '150-200', '200-250', '250-300', 'notTracking'].includes(answers.waterIntake?.value)) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 22]; // id для "Профілактика сечокам’яної хвороби"
  }

  // Тип води
  if (['tapWater', 'filteredWater', 'bottledWater', 'specialCatWater', 'wellWater'].includes(answers.waterType?.value)) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 8]; // id для "Для кастрованих/стерилізованих"
  }

  // Тип харчування
  if (answers.dietType?.value?.some(type => ['dryFood', 'wetFood', 'homemade', 'tableScraps'].includes(type))) {
    characteristics['vidKormu'] = [...(characteristics['vidKormu'] || []), 1, 2, 3, 4]; // id для "Основне харчування", "Кормова добавка", "Ласощі", "Фітоміни"
  }

  return characteristics;
}
